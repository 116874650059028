import { roles } from '../../constants/roles';
import { DelegatedUsersTableProps } from './types';
import { InfoIcon } from '../common/InfoIcon';
import { Button } from 'reactstrap';
import { SurveyUserRoles } from '../../constants/users';
import { QUESTION, SURVEY } from '@constants/terminology';
import { SimpleTooltip, StaffIcon } from '@g17eco/molecules';
import { hasAtLeastOneRoleLevel, isAssignedToAll, RoleLevel } from '@utils/delegation-users';
import { User } from '@api/survey-users';
import { UserText } from '@apps/company-tracker/components/delegation';

export const UserRow = ({
  userRole,
  user,
  utrvs,
  handleRemoveUser,
  isReadonly,
}: Pick<DelegatedUsersTableProps, 'utrvs' | 'handleRemoveUser' | 'isReadonly'> & {
  userRole: SurveyUserRoles;
  user: User;
}) => {
  return (
    <div className='d-flex align-items-center'>
      <CircleIcon userRole={userRole} user={user} utrvs={utrvs} />
      <UserText user={user} />
      {user.isStaff ? <StaffIcon /> : null}
      {isReadonly ? null : <Action userRole={userRole} user={user} handleRemoveUser={handleRemoveUser} />}
    </div>
  );
};

const CircleIcon = ({
  userRole,
  user,
  utrvs,
}: Pick<DelegatedUsersTableProps, 'utrvs'> & { userRole: SurveyUserRoles; user: User }) => {
  const isFullCircle = isAssignedToAll(userRole, user, utrvs?.length || 0);

  const icon = isFullCircle ? 'fa-circle' : 'fa-circle-half-stroke';
  const tooltip = `Delegated as a ${roles[userRole].shortName?.toLowerCase()} for ${
    isFullCircle ? 'all' : 'some'
  } of the ${QUESTION.PLURAL} selected.`;

  return (
    <SimpleTooltip text={tooltip}>
      <i className={`text-ThemeIconDark mr-3 fa-solid ${icon}`}></i>
    </SimpleTooltip>
  );
};

const Action = ({
  userRole,
  user,
  handleRemoveUser,
}: { userRole: SurveyUserRoles; user: User } & Pick<DelegatedUsersTableProps, 'handleRemoveUser'>) => {
  if (hasAtLeastOneRoleLevel([RoleLevel.Utrv], userRole, user)) {
    return (
      <Button
        color='link'
        className='mx-1 p-0 ml-auto mr-2'
        onClick={() => handleRemoveUser({ role: userRole, userId: user._id })}
      >
        <i className='fas fa-times text-ThemeDangerMedium' />
      </Button>
    );
  }

  if (hasAtLeastOneRoleLevel([RoleLevel.Initiative], userRole, user)) {
    const text = `User has the ${roles[
      userRole
    ].shortName?.toLowerCase()} permission assigned to them at the platform level and cannot be removed from ${
      QUESTION.SINGULAR
    } level delegation.`;
    return (
      <div className='ml-auto mr-2'>
        <InfoIcon text={text} />
      </div>
    );
  }

  if (hasAtLeastOneRoleLevel([RoleLevel.Survey], userRole, user)) {
    const text = `User has the ${roles[userRole].shortName?.toLowerCase()} permission assigned to them at the ${
      SURVEY.SINGULAR
    } level and cannot be removed from ${QUESTION.SINGULAR} level delegation.`;
    return (
      <div className='ml-auto mr-2'>
        <InfoIcon text={text} />
      </div>
    );
  }

  return null;
};

import {
  heading,
  heading2,
  heading3,
  imageWrapper,
  justifiedParagraph,
  numberList,
  pagebreak,
  paragraph,
  spacer,
  title,
} from '@components/report-output/document-structure';
import { numberingStyles } from '@components/report-output/styles';
import {
  AlignmentType,
  Document,
  Footer,
  HorizontalPositionAlign,
  HorizontalPositionRelativeFrom,
  ImageRun,
  IParagraphStyleOptions,
  PageNumber,
  PageOrientation,
  Paragraph,
  SectionType,
  Table,
  VerticalPositionRelativeFrom,
} from 'docx';
import G17EcoLogo from '@g17eco/images/g17Eco.svg';
import MTLogo from '@g17eco/images/apps/Materiality_Tracker_logo.svg';
import { SafeTextRun } from '@utils/docx';
import { AssessmentData, MaterialitySurveyModelMinData } from '@apps/materiality-tracker/api/materiality-assessment';
import { DATE, formatDateUTC } from '@utils/date';
import { InitiativeData } from '@g17eco/types/initiative';
import { AssessmentTableGenerator } from './assessment-table-generator';
import { UniversalTrackerBlueprintMin } from '@g17eco/types/universalTracker';
import { getImageBase64FromSVGUrl } from '@utils/image';

const detailedStyles: IParagraphStyleOptions[] = [
  {
    id: 'Normal',
    name: 'Normal',
    quickFormat: true,
    run: {
      size: 24,
      font: 'Helvetica Neue',
    },
  },
  {
    id: 'Title',
    name: 'Title',
    basedOn: 'Normal',
    next: 'Normal',
    quickFormat: true,
    run: {
      color: '691472',
      size: 80,
      font: 'HELVETICA NEUE CONDENSED',
      bold: true,
    },
    paragraph: {
      spacing: {
        before: 300,
        after: 200,
      },
    },
  },
  {
    id: 'Heading1',
    name: 'Heading 1',
    basedOn: 'Normal',
    next: 'Normal',
    quickFormat: true,
    run: {
      color: '691472',
      size: 56,
      font: 'HELVETICA NEUE CONDENSED',
      bold: true,
    },
    paragraph: {
      spacing: {
        before: 300,
        after: 200,
      },
    },
  },
  {
    id: 'Heading2',
    name: 'Heading 2',
    basedOn: 'Normal',
    next: 'Normal',
    quickFormat: true,
    run: {
      color: '691472',
      size: 40,
      font: 'HELVETICA NEUE CONDENSED',
      bold: true,
    },
    paragraph: {
      spacing: {
        before: 300,
        after: 200,
      },
    },
  },
  {
    id: 'Heading3',
    name: 'Heading 3',
    basedOn: 'Normal',
    next: 'Normal',
    quickFormat: true,
    run: {
      color: '691472',
      size: 26,
      font: 'HELVETICA NEUE CONDENSED',
      bold: true,
    },
    paragraph: {
      spacing: {
        before: 240,
        after: 120,
      },
    },
  },
  {
    id: 'plain',
    name: 'Plain',
    basedOn: 'Normal',
    next: 'Normal',
    quickFormat: true,
    run: {
      size: 24,
    },
  },
  {
    id: 'plainWhite',
    name: 'Plain White',
    basedOn: 'Normal',
    next: 'Normal',
    quickFormat: true,
    run: {
      size: 20,
      font: 'Arial',
      color: 'FFFFFF',
    },
  },
  {
    id: 'bold',
    name: 'Bold',
    basedOn: 'Normal',
    next: 'Normal',
    quickFormat: true,
    run: {
      size: 20,
      bold: true,
    },
  },
];

interface DetailedReportGeneratorData {
  initiative: InitiativeData;
  survey: MaterialitySurveyModelMinData;
  financialResult: AssessmentData[];
  mappedUtrs: UniversalTrackerBlueprintMin[];
}

export const DetailedReportGenerator = async (generatorData: DetailedReportGeneratorData): Promise<Document> => {
  const { survey, financialResult, initiative, mappedUtrs } = generatorData;
  const G17EcoLogoBase64 = await getImageBase64FromSVGUrl(G17EcoLogo);
  const MTLogoBase64 = await getImageBase64FromSVGUrl(MTLogo);
  const tableGenerator = new AssessmentTableGenerator({ result: financialResult, questions: mappedUtrs });

  const g17ecoLogo = new ImageRun({
    data: G17EcoLogoBase64 ? G17EcoLogoBase64 : '',
    transformation: {
      width: 108,
      height: 28,
    },
  });

  const materialtrackerLogo = new ImageRun({
    data: MTLogoBase64 ? MTLogoBase64 : '',
    transformation: {
      width: 280,
      height: 102,
    },
    floating: {
      horizontalPosition: {
        relative: HorizontalPositionRelativeFrom.PAGE,
        align: HorizontalPositionAlign.CENTER,
      },
      verticalPosition: {
        offset: 1674250,
        relative: VerticalPositionRelativeFrom.PAGE,
      },
    },
  });

  const welcomePageContent = [
    imageWrapper(materialtrackerLogo),
    spacer(5000),
    title('FINANCIAL ASSESSMENT', { alignment: AlignmentType.CENTER }),
    heading2(formatDateUTC(survey.effectiveDate, DATE.MONTH_YEAR).toUpperCase(), {
      alignment: AlignmentType.CENTER,
      textRun: { color: '434343' },
    }),
    spacer(400),
    heading2('WORLDWIDE GENERATION', { alignment: AlignmentType.CENTER, textRun: { color: '434343' } }),
    pagebreak(),
  ];

  const tableOfContentPageContent = [
    heading('TABLE OF CONTENTS'),
    paragraph('INTRODUCTION'),
    paragraph('G17ECO MATERIALITY TRACKER'),
    paragraph('METHODOLOGY'),
    paragraph('RESULTS'),
    numberList('numbering-letter-one', 'IDENTIFYING MATERIAL ASPECTS'),
    numberList('numbering-letter-one', 'RESULTS (DEFINING MATERIAL BOUNDARIES)'),
    paragraph('SUMMARY'),
    paragraph('ABOUT G17ECO'),
    paragraph('APPENDIX'),
    numberList('numbering-letter-two', 'DEFINITIONS'),
    numberList('numbering-letter-two', 'DETAILED DESCRIPTION OF SCORING MECHANISMS'),
    numberList('numbering-letter-two', 'FINANCIAL IMPACT BASED ON SCORED DATA'),
    numberList('numbering-letter-two', 'STAKEHOLDER INTERACTIONS'),
    numberList('numbering-letter-two', 'BENEFITS OF MATERIALITY ASSESSMENT'),
    numberList('numbering-letter-two', 'TABLE A: TOPIC SCORE AND DESCRIPTIONS'),
    numberList('numbering-letter-two', 'TABLE B: TOPICS TO RECOMMENDED METRIC MAPPING'),
    numberList('numbering-letter-two', 'TABLE C: ACTIONS BASED ON RECOMMENDED TOPICS'),
    pagebreak(),
  ];

  const introductionPageContent = [
    heading('INTRODUCTION'),
    justifiedParagraph(
      `This report outlines the findings of the sustainability financial materiality assessment conducted using the Materiality Tracker (MT) for ${initiative.name}. The assessment aims to pinpoint the most significant sustainability-related financial issues pertinent to the company’s operations. Leveraging advanced data analytics and industry benchmarks, and incorporating specific sustainability considerations relevant to ${initiative.name} sector, this analysis delivers critical insights into financially material topics. This report serves as a key resource for ${initiative.name}, guiding strategic financial planning and enhancing transparent reporting on sustainability performance and risks.`
    ),
    heading('G17ECO MATERIALITY TRACKER'),
    justifiedParagraph(
      `Materiality Tracker’s financial materiality assessment uses a rigorous methodology to identify and prioritise key financial issues for ${initiative.name}. Advanced data analytics examine diverse sources, including financial reports, market analyses, and regulatory documents. Industry benchmarks ensure accuracy and contextual relevance, delivering precise, actionable insights essential for strategic decision-making and financial reporting.`
    ),
    justifiedParagraph(
      `The assessment highlights financially material topics critical to ${initiative.name}’s stability and growth, such as revenue growth, cost management, asset valuation, and financial compliance. Each issue is evaluated for its impact on financial performance, investor decisions, and long-term profitability.`
    ),
    justifiedParagraph(
      'Proactively addressing these topics strengthens financial performance, mitigates risks, and enhances market competitiveness. The findings provide actionable insights for refining strategies, setting objectives, and ensuring transparent reporting. As a dynamic process, financial materiality assessment evolves with market trends, investor expectations, and regulatory changes.'
    ),
    justifiedParagraph(
      `By incorporating these insights into core strategies, ${initiative.name} reinforces financial resilience, optimises its market position, and drives sustainable growth. This proactive approach ensures alignment with changing conditions while laying a solid foundation for strategic financial planning and decision-making.`
    ),
    pagebreak(),
    heading('METHODOLOGY'),
    justifiedParagraph(
      `The impact assessment conducted using Materiality Tracker employed a robust methodology to evaluate the potential impact of identified material topics on ${initiative.name}'s sustainability performance. This exercise was not designed to assess how well ${initiative.name} is currently performing in these areas but to determine the current impact of their industry and identify opportunities for ${initiative.name} to improve industry performance.`
    ),
    justifiedParagraph(
      `Materiality Tracker reviewed the definitions of each material topic and identified relevant metrics to evaluate them. For each metric, reputable resources were used to rank the industry’s performance, with results normalized out of 100. Where ${initiative.name}’s industry underperforms on a metric, there is greater potential for ${initiative.name} to make a substantive impact, reflected in a higher score.`
    ),
    justifiedParagraph(
      `After analyzing financially material details for ${initiative.name}, Materiality Tracker categorized these key topics into five pillars—People, Partnerships, Planet, Prosperity, and Principles—and aligned them to seven organizational boundaries. Reporting metrics aligned to globally recognized standards were also recommended, ensuring compliance with industry best practices. For detailed descriptions of these material topics and their recommended metrics, refer to the appendices at the end of this report.`
    ),
  ];

  const resultPageContent = [
    heading('RESULTS'),
    heading2('IDENTIFYING MATERIAL ASPECTS'),
    justifiedParagraph(
      'The five sustainability pillars—People, Partnership, Planet, Prosperity, and Principle—focus on fostering social well-being, building collaborative relationships, protecting the environment, ensuring economic growth, and upholding ethical governance. Together, they provide a holistic approach to sustainable development, balancing social, environmental, & economic goals while promoting responsible and inclusive business practices.'
    ),
    ...tableGenerator.getStandardsTables(),
    pagebreak(),
    heading2('DEFINING MATERIAL BOUNDARIES'),
    justifiedParagraph(
      `To determine where each material topic can be influenced, Materiality Tracker helped ${initiative.name} define instrumental boundaries that impact key material topics. These boundaries assist ${initiative.name} in understanding where their scope of influence falls and how to measure, track, and report on the material topics identified in the materiality assessment.`
    ),
    justifiedParagraph(
      `As part of this process, the topics were mapped across ${initiative.name}'s value chain. While many topics are relevant to multiple, or all, stages of the value chain, topics were assigned according to the ranking impacts associated with ${initiative.name} business and sphere of influence.`
    ),
    heading3('Key material topics aligned with pillars and boundaries'),
    ...tableGenerator.getBoundariesTables(),
    pagebreak(),
    heading2('MAPPING BY MATERIAL BOUNDARIES ONLY'),
    justifiedParagraph(
      `Material topics were also mapped by material boundaries to better illustrate where ${initiative.name} influence is most needed. Please note material topics can appear across multiple material boundaries.`
    ),
    justifiedParagraph(
      'As shown in the following table, internal focus would be best spent on BOUNDARIES WITH THE MOST TOPICS. A topic can appear in more than one boundary.'
    ),
    paragraph('*The top 5 scoring topics for your organisation have been highlighted in bold.', {
      textRun: { italics: true },
    }),
    tableGenerator.getTopTopicsPerBoundaryTable(),
  ];

  const summaryPageContent = [
    heading('SUMMARY'),
    justifiedParagraph(
      `This report, and the information gathered to support it, present the material topics relevant to ${initiative.name} business as determined by various stakeholder analysis and supplemental sources analyzed by G17Eco Materiality Tracker.`
    ),
    justifiedParagraph(
      `This analysis satisfies the globally recognized standards requirement for conducting a materiality assessment as part of the sustainability reporting process, should ${initiative.name} wish to report externally according to these standards. G17Eco can continue to support ${initiative.name} efforts towards sustainability through assistance with strategy development, reporting, metrics, or other services as desired.`
    ),
    heading('ABOUT G17ECO'),
    justifiedParagraph(
      'Worldwide Generation is an international team of purpose-driven sustainability and fintech experts united in driving a global movement for change. To enable this change, Worldwide Generation created the G17eco platform to accelerate the financing and delivery of the Sustainable Development Goals (SDGs).'
    ),
    justifiedParagraph(
      'Launched at the end of 2020, G17eco is the culmination of 300+ expert contributors to the platform over 5 years - including major support from the UK Government and the City of London.'
    ),
    pagebreak(),
    heading('APPENDIX - DEFINITIONS'),
    justifiedParagraph([
      new SafeTextRun({ text: 'Materiality Assessment: ', bold: true }),
      new SafeTextRun(
        'Utilising responses from your internal questionnaire, we identify your organisation’s distinct material topics, reflecting unique priorities and challenges.'
      ),
    ]),
    justifiedParagraph([
      new SafeTextRun({ text: 'Mapping to Pillars and Material Boundaries: ', bold: true }),
      new SafeTextRun(
        'These topics are systematically mapped to relevant sustainability pillars and boundaries, creating a structured framework to assess their influence on both your sustainability efforts and overall business operations.'
      ),
    ]),
    justifiedParagraph([
      new SafeTextRun({ text: 'Alignment with Global Standards: ', bold: true }),
      new SafeTextRun(
        'We align these topics with metrics from globally recognized standards, ensuring compliance and relevance. While standard suggestions are adaptable, you can modify, omit, or reprioritize these metrics based on your evolving organisational needs or strategic shifts. This flexibility ensures that the integration into our Company Tracker platform fully supports your specific reporting requirements.'
      ),
    ]),
    justifiedParagraph([
      new SafeTextRun({ text: 'Reporting and Sustainability Report: ', bold: true }),
      new SafeTextRun(
        'Completing your reporting cycle provides you with the essential data needed to craft and finalise your comprehensive sustainability report and refine your sustainability strategy and initiatives going forward.'
      ),
    ]),
    heading2('ENVIRONMENTAL, SOCIAL AND GOVERNANCE (ESG)'),
    justifiedParagraph([
      new SafeTextRun({ text: 'Environmental (E): ', bold: true }),
      new SafeTextRun(
        'This category addresses your company’s environmental impact, focusing on efficient resource management, waste reduction, pollution control, and conservation. It promotes practices that minimise environmental harm and enhance sustainability.'
      ),
    ]),
    justifiedParagraph([
      new SafeTextRun({ text: 'Social (S): ', bold: true }),
      new SafeTextRun(
        'This aspect covers your company\'s relationships with employees, suppliers, customers, and communities. It includes responsibilities like fair labour practices, diversity, inclusion, philanthropy, and active community involvement, stressing the importance of considering stakeholders in decision-making.'
      ),
    ]),
    justifiedParagraph([
      new SafeTextRun({ text: 'Governance (G): ', bold: true }),
      new SafeTextRun(
        'This segment covers your company’s governance structures, including executive pay, audits, internal controls, and shareholder rights. It includes governance practices that ensure compliance, integrity, and sustainable business success.'
      ),
    ]),
    heading2('FIVE PILLARS'),
    justifiedParagraph([
      new SafeTextRun({ text: 'Planet: ', bold: true }),
      new SafeTextRun(
        'Focuses on reducing ecological footprints and protecting ecosystems, emphasising sustainable environmental practices.'
      ),
    ]),
    justifiedParagraph([
      new SafeTextRun({ text: 'People: ', bold: true }),
      new SafeTextRun(
        'Highlights the importance of ethical and beneficial business practices toward labour, the community, and regions of operation, aiming to improve inclusivity and quality of life.'
      ),
    ]),
    justifiedParagraph([
      new SafeTextRun({ text: 'Prosperity: ', bold: true }),
      new SafeTextRun(
        'Pertains to how the business contributes to economic development while ensuring financial stability and growth, fostering a stable and prosperous economy for all stakeholders.'
      ),
    ]),
    justifiedParagraph([
      new SafeTextRun({ text: 'Partnership: ', bold: true }),
      new SafeTextRun(
        'Encourages collaboration with various stakeholders, including NGOs and governments, to pursue sustainable goals collaboratively.'
      ),
    ]),
    justifiedParagraph([
      new SafeTextRun({ text: 'Principle: ', bold: true }),
      new SafeTextRun(
        'Stresses adherence to ethical standards, transparency, and legal compliance, ensuring all business operations maintain the highest integrity.'
      ),
    ]),
    heading2('BUSINESS CATEGORIES (BOUNDARIES)'),
    justifiedParagraph([
      new SafeTextRun({ text: 'Leadership: ', bold: true }),
      new SafeTextRun(
        'Focuses on strategic decision-making and leadership practices that influence the organisation’s direction and governance.'
      ),
    ]),
    justifiedParagraph([
      new SafeTextRun({ text: 'Research and Development (R&D): ', bold: true }),
      new SafeTextRun(
        'Involves activities aimed at developing or enhancing products, technologies, or services, underpinning the organisation’s innovation efforts.'
      ),
    ]),
    justifiedParagraph([
      new SafeTextRun({ text: 'Supply Chain: ', bold: true }),
      new SafeTextRun(
        'Examines all processes from raw material transformation to final product delivery, emphasising sustainable practices in procurement, resource use, and labour conditions.'
      ),
    ]),
    justifiedParagraph([
      new SafeTextRun({ text: 'Product or Services: ', bold: true }),
      new SafeTextRun(
        'Concerns the design, creation, and delivery of the company’s offerings, prioritising safety, quality, and sustainability.'
      ),
    ]),
    justifiedParagraph([
      new SafeTextRun({ text: 'Distribution: ', bold: true }),
      new SafeTextRun(
        'Manages the logistics of transporting and distributing goods, optimising routes and methods to reduce environmental impacts.'
      ),
    ]),
    justifiedParagraph([
      new SafeTextRun({ text: 'Communities: ', bold: true }),
      new SafeTextRun(
        'Engages with local and global communities where the organisation operates, focusing on responsible, economic, and environmental contributions.'
      ),
    ]),
    justifiedParagraph([
      new SafeTextRun({ text: 'Experiences: ', bold: true }),
      new SafeTextRun(
        'Encompasses the company’s interactions with customers and employees, aiming to create value and enhance the workplace environment.'
      ),
    ]),
    heading2('CRITERIA FOR FINANCIAL ASSESSMENTS'),
    justifiedParagraph(
      'MT utilizes a sophisticated scoring system designed to evaluate the financial significance of each identified issue based on a combination of factors. This scoring mechanism is pivotal in quantifying and prioritizing financial risks and opportunities. Here’s how it works:'
    ),
    justifiedParagraph([
      new SafeTextRun({ text: 'Quantitative Metrics: ', bold: true }),
      new SafeTextRun(
        'Financial issues are scored on a scale that incorporates quantitative financial data, such as potential impact on revenue, costs, and assets. This includes both historical data and projected figures to assess potential future impacts.'
      ),
    ]),
    justifiedParagraph([
      new SafeTextRun({ text: 'Qualitative Assessments: ', bold: true }),
      new SafeTextRun(
        'Qualitative factors are also integrated into the scoring, including regulatory compliance risks, potential for reputational damage, and strategic importance. These are assessed through expert analysis and stakeholder feedback captured via questionnaires.'
      ),
    ]),
    justifiedParagraph([
      new SafeTextRun({ text: 'Weighting Factors: ', bold: true }),
      new SafeTextRun(
        `Each scoring criterion is weighted differently based on its relative importance to ${initiative.name}. For example, issues that directly affect financial stability may be given a higher weight compared to those with less immediate financial consequences.`
      ),
    ]),
    justifiedParagraph([
      new SafeTextRun({ text: 'Threshold Levels: ', bold: true }),
      new SafeTextRun(
        'Thresholds are set to differentiate between levels of materiality. Issues that score above a certain threshold are classified as high priority and are flagged for immediate action or closer monitoring.'
      ),
    ]),
    heading2('FINANCIAL IMPACT BASED ON SCORED DATA'),
    justifiedParagraph(
      'Once the financial issues are scored, MT analyzes the aggregated data to determine their overall financial impact. This analysis involves several steps:'
    ),
    justifiedParagraph([
      new SafeTextRun({ text: 'Impact Mapping: ', bold: true }),
      new SafeTextRun(
        'High-scoring issues are analyzed to identify potential impacts on overall performance. This mapping helps visualize areas of risk and opportunity based on general industry trends and common strategic goals.'
      ),
    ]),
    justifiedParagraph([
      new SafeTextRun({ text: 'Sensitivity Analysis: ', bold: true }),
      new SafeTextRun(
        'A sensitivity analysis is conducted for high-priority issues to understand how changes in external conditions or typical strategic adjustments might affect the severity of these issues.'
      ),
    ]),
    pagebreak(),
    heading('STAKEHOLDER INTERACTIONS'),
    heading2('IDENTIFYING STAKEHOLDERS'),
    justifiedParagraph(
      `Although Materiality Tracker (MT) does not directly interact with external stakeholders through traditional face-to-face methods, it can still capture their perspectives. During the research and development phase of the tool, World Wide Generation designed the questionnaire by gathering comprehensive financial and sustainability data from a wide array of external stakeholders, including investors, financial analysts and regulatory authorities. This provided critical insights into investor expectations, financial trends, market trends and financial strategies. In doing so, the MT is able to understand the financial strategies that influence ${initiative.name}.`
    ),
    justifiedParagraph(
      'By analyzing responses to these questions, the MT ensures that the financially material topics identified are closely aligned with stakeholder concerns and priorities. This method aims to synchronize the company’s financial planning and strategies with stakeholder expectations, ensuring responsiveness to market and regulatory dynamics while also minimizing unconscious bias.'
    ),
    justifiedParagraph(
      'The process of selecting and scoring material financial issues is highly automated within the MT. This system uses advanced algorithms to analyze questionnaire data, identifying key financial issues based on their potential impact and relevance to stakeholders. The scoring mechanism assesses each issue’s significance and urgency, which helps prioritize financial actions and reporting.'
    ),
    heading2('STAKEHOLDERS ENGAGEMENT STRATEGIES'),
    justifiedParagraph(
      `MT leverages online data and publicly available financial information alongside questionnaire insights to form a holistic view of stakeholder expectations & financial trends. This strategy enriches the materiality assessment by incorporating a broad spectrum of financial stakeholder perspectives without direct engagement. The comprehensive analysis of this data ensures that ${initiative.name} sustainability financial management practices are well-informed, transparent, and aligned with both stakeholder interests and market requirements.`
    ),
    heading2('BENEFITS OF MATERIALITY ASSESSMENT'),
    justifiedParagraph(
      'In today’s dynamic economic environment, organizations are increasingly recognizing the critical importance of robust financial management practices. Financial performance and compliance issues have a significant impact on a company’s long-term viability, investor relations, and market position. To effectively navigate these challenges, companies need to identify and prioritise financial issues that are most relevant to their operations and financial stakeholders.'
    ),
    justifiedParagraph(
      `The financial materiality assessment conducted using G17eco Materiality Tracker for ${initiative.name} is a vital step in this process. By leveraging advanced data analytics and industry benchmarks, while considering specific financial factors relevant to our sector, the assessment provides a detailed understanding of the key financial topics specific to ${initiative.name}. This enables the company to gain insights into areas where it can optimise financial performance, mitigate risks, and align its strategies with shareholder and market expectations.`
    ),
    justifiedParagraph(
      'This report sets the context for the financial materiality assessment, highlighting the growing importance of financial scrutiny and the need for organisations to integrate sophisticated financial analysis into their core business strategies. It recognises that managing financial materiality is not just about meeting regulatory requirements or managing market expectations, but also presents an opportunity for strategic financial planning, growth, and long-term profitability.'
    ),
    justifiedParagraph(
      `By conducting this financial materiality assessment, ${initiative.name} demonstrates its commitment to understanding and addressing the most significant financial issues affecting its operations. The findings will provide a solid foundation for refining financial strategies, setting precise goals, and driving improvements in areas critical to financial health.`
    ),
    justifiedParagraph(
      'This report serves as a comprehensive guide to the financial materiality assessment, offering insights into the methodology, data sources, and evaluation criteria used to identify key financial topics. It provides a roadmap for integrating sophisticated financial management practices, enhancing stability, and contributing to sustainable economic performance.'
    ),
    justifiedParagraph(
      `By embracing the findings of this financial materiality assessment, ${initiative.name} can navigate the complex financial landscape, enhance shareholder value, and position itself as a leader in sound financial management.`
    ),
  ];

  const appendixContent = [
    heading('APPENDIX SECTION'),
    heading2('TABLE A: TOPIC SCORE AND DESCRIPTIONS'),
    tableGenerator.getTopicScoresWithDescTable(),
    pagebreak(),
    heading2('TABLE B: TOPIC TO RECOMMENDED METRICS MAPPING'),
    tableGenerator.getTopicMappedMetricsTable(),
    pagebreak(),
    heading2('TABLE C: ACTIONS BASED ON RECOMMENDED METRICS'),
    tableGenerator.getTopicActionsTable(),
  ]

  const footer = () => {
    return new Footer({
      children: [
        new Paragraph({
          children: [
            new SafeTextRun({
              children: [PageNumber.CURRENT],
            }),
          ],
        }),
        new Paragraph({
          children: [g17ecoLogo],
          alignment: AlignmentType.RIGHT,
        }),
      ],
    });
  };

  const portraitPage = (children: (Paragraph | Table)[]) => {
    return {
      properties: {
        type: SectionType.NEXT_PAGE,
      },
      footers: {
        default: footer(),
      },
      children,
    };
  };

  const landscapePage = (children: (Paragraph | Table)[]) => {
    return {
      properties: {
        type: SectionType.NEXT_PAGE,
        page: {
          size: {
            orientation: PageOrientation.LANDSCAPE,
          },
        },
      },
      footers: {
        default: footer(),
      },
      children,
    };
  };

  return new Document({
    features: {
      updateFields: true,
    },
    styles: {
      paragraphStyles: detailedStyles,
    },
    numbering: {
      config: numberingStyles,
    },
    sections: [
      portraitPage([...welcomePageContent, ...tableOfContentPageContent, ...introductionPageContent]),
      landscapePage(resultPageContent),
      portraitPage(summaryPageContent),
      landscapePage(appendixContent),
    ],
  });
};

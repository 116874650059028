import { User } from '@api/survey-users';
import { SimpleTooltip } from '@g17eco/molecules';
import { ellipsis } from '@utils/index';
import { getFullName, isOnboardingUser } from '@utils/user';

export const UserText = ({ user }: { user: User }) => {
  if (isOnboardingUser(user.status)) {
    const limit = 30;
    const name = (user.firstName || user.email) ?? '';
    const tooltip = (name.length || 0) > limit ? name : undefined;
    return (
      <span className={'pendingText'}>
        <i title='Invitation sent and waiting confirmation' className='fas fa-paper-plane pending mr-1' />
        <SimpleTooltip text={tooltip}>{ellipsis(name || '', limit)}</SimpleTooltip> (pending)
      </span>
    );
  }

  const fullName = getFullName(user, user.email);

  if (user.active) {
    return <span>{fullName}</span>;
  }

  return (
    <span className={'user-inactive'}>
      {fullName}
      <SimpleTooltip
        id={`optionTooltip-${user._id}`}
        text={'User is not active'}
        component={<i className='fas fa-info-circle' />}
      />
    </span>
  );
};

import React, { LegacyRef, useEffect } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
  ColumnDef
} from '@tanstack/react-table';
import { getTableConfiguration } from '../../../../utils/universalTracker';
import classNames from 'classnames';
import { formatCalculationNumber } from '../../../../utils/formula';
import { RowDataInfo, RowStatus, ValidationRow } from '../../question/questionInterfaces';
import { loadValueListById, valueListSelectors } from '../../../../slice/valueListSlice';
import { batch, useDispatch, useSelector } from 'react-redux';
import { getUnitDescription, UnitTypes } from '../../../../utils/units';
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import Papa from 'papaparse';
import FileSaver from 'file-saver';
import { InputColumn, TableColumn, TableInputProps, ValueTable } from '../input/table/InputInterface';
import './TableInputView.scss'
import { hasMinAndMaxColumnType } from '../../../../types/universalTracker';
import { getClasses, getControlIcon } from './utils';
import { SimpleTooltip } from '@g17eco/molecules';
import { ValueWithValidation } from '@components/survey/form/view/ValueWithValidation';

const getDisplayUnit = (c: TableColumn): string => {
  if (hasMinAndMaxColumnType(c.type, c.validation?.min, c.validation?.max)) {
    return '%';
  }
  if (c.unitType && c.unitType === UnitTypes.currency) {
    return '';
  }
  return c.unit ?? '';
};

const emptyData: RowDataInfo = {
  id: 0,
  rowStatus: RowStatus.original,
  data: [],
};

interface TableProps {
  columns: ColumnDef<RowDataInfo>[];
  data: RowDataInfo[];
  editRowId: number;
  hasBeforeAddon: boolean;
  hasAfterAddon: boolean;
}

function Table({ columns, data, editRowId, hasBeforeAddon, hasAfterAddon }: TableProps) {
  // Use the state and functions returned from useTable to build your UI
  const { getHeaderGroups, getRowModel } = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    // getPaginationRowModel: getPaginationRowModel(), // @WARNING - If you enable this, it will limit to pageSize=10, with no pagination!
    // @TODO Needs pagination implementation
  });

  // Render the UI for your table
  return (
    <table className={classNames({ 'before-addon-table': hasBeforeAddon, 'after-addon-table': hasAfterAddon })}>
      <thead>
        {getHeaderGroups().map((headerGroup) => {
          return (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, i) => {
                return <th key={header.id}>{flexRender(header.column.columnDef.header, header.getContext())}</th>;
              })}
            </tr>
          );
        })}
      </thead>
      <tbody>
        {getRowModel().rows.map((row) => {
          const isActive = row.original.id === editRowId;
          const classes = `${getClasses(row.original, editRowId)} ${isActive ? 'active-edit-row' : ''}`;
          return (
            <tr key={row.id} className={classes}>
              {row.getVisibleCells().map((cell) => {
                const { column } = cell;
                const meta = column.columnDef.meta as any;
                return (
                  <td key={cell.id} className={meta?.cellProps?.className}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}


type RowUpdateFn = (row: RowDataInfo, id?: number) => void;

type TableInputViewProps = Pick<TableInputProps, 'universalTracker' | 'addons' | 'isDownloadBtnBottom' | 'unitConfig'> & {
  rowData: RowDataInfo[],
  editRowId: number;
  handleEditRow?: RowUpdateFn,
  handleCopyRow?: RowUpdateFn,
  handleDeleteRow?: RowUpdateFn,
  handleClearDeleteRow?: RowUpdateFn,
  disabled?: boolean,
  ignoreType?: boolean,
  ref?: LegacyRef<any>,

  className?: string,
  showTableDownload?: boolean;
  extractColumnCode?: string;
  validations?: ValidationRow[];
}

const TableInputView = (props: TableInputViewProps) => {
  const {
    universalTracker,
    rowData,
    editRowId,
    handleEditRow,
    handleCopyRow,
    handleDeleteRow,
    handleClearDeleteRow,
    disabled,
    ignoreType,
    ref,
    showTableDownload = true,
    extractColumnCode,
    addons,
    isDownloadBtnBottom,
    validations,
  } = props;

  const dispatch = useDispatch();
  const valueListMap = useSelector(valueListSelectors.selectEntities);
  const tableConfiguration: ValueTable | undefined = getTableConfiguration(universalTracker, ignoreType);
  useEffect(() => {
    const emptyValueList = tableConfiguration?.columns.filter(
      (column) => {
        const valueListId = column.listId;
        return valueListId && !valueListMap[valueListId]?.loading && !valueListMap[valueListId]?.loaded
      }
    );
    batch(() => {
      emptyValueList?.map((valueListItem) => valueListItem.listId && dispatch(loadValueListById(valueListItem.listId)));
    });
  }, [dispatch, tableConfiguration?.columns, valueListMap]);

  if (!tableConfiguration || !Array.isArray(rowData)) {
    return null;
  }
  const { columns } = tableConfiguration;
  const beforeAddons = addons?.before ?? [];
  const afterAddons = addons?.after ?? [];

  const actions = (info: RowDataInfo) => {
    const { id, isRemoved } = info;

    return <>
      {handleCopyRow ?
        <DropdownItem onClick={() => handleCopyRow(info, id)} ref={ref}>
          <i className='fa-regular fa-copy text-ThemeAccentMedium mr-2' />Copy row data
        </DropdownItem> : null
      }
      {handleEditRow ?
        <DropdownItem onClick={() => handleEditRow(info, id)} ref={ref}>
          <i className='fas fa-pencil-alt text-ThemeAccentMedium mr-2' />Edit data
        </DropdownItem> : null
      }
      {!isRemoved && handleDeleteRow ?
        <DropdownItem onClick={() => handleDeleteRow(info, id)}>
          <i className='fa fa-trash-alt text-ThemeDangerMedium mr-2' />Delete row
        </DropdownItem>
        : null
      }
      {
        isRemoved && handleClearDeleteRow ?
          <DropdownItem onClick={() => handleClearDeleteRow(info, id)}>
            <i className='fa fa-trash-alt mr-2' />Delete row
          </DropdownItem>
          : null
      }
    </>
  }

  const status = (info: RowDataInfo) => {
    const i = getControlIcon(info, editRowId, validations);
    if (!i) {
      return '';
    }

    return (
      <div className='status-icon text-center'>
        <SimpleTooltip text={i.tooltip}>
          <i data-testid={i.testId} className={i.icon} />
        </SimpleTooltip>
      </div>
    );
  };

  const getValueFromOptions = (value: number | string | undefined, colCode: string, unit: string, decimal?: number) => {
    if (value === undefined) {
      return;
    }

    const c = columns.find(c => c.code === colCode);
    const hasOptions = c && c.options && c.options.length > 0;
    const hasList = c && c.listId;
    if (hasOptions || hasList) {
      const options = c.listId ? (valueListMap[c.listId]?.options ?? []) : (c.options ?? []);
      const op = options.find(o => o.code === value);
      return op ? op.name : value;
    }

    // [GU-5815] - We no longer want to format numbers here, as it hides details from the user.
    // leaving for reference in case we need to revert.
    // this could still be a problem with number type that comes from excel import sheet.
    // if (typeof decimal === 'number' && value !== '') {
    //   const formattedValue = parseFloat(String(value)).toLocaleString('en-GB', {
    //     maximumFractionDigits: decimal,
    //     minimumFractionDigits: decimal,
    //   });
    //   return `${formattedValue}${unit}`;
    // }

    return `${value}${unit}`;
  }

  const getValueByColumn = (
    d: InputColumn[] | Record<string, string | number | undefined>,
    c: TableColumn
  ) => {
    if (Array.isArray(d)) {
      const column = d.find(col => col.code === c.code);
      return column ? column.value : '';
    }
    return d[c.code];
  }

  const getFormattedValueByColumn = (
    d: InputColumn[] | Record<string, string | number | undefined>,
    c: TableColumn,
  ) => {
    const value = getValueByColumn(d, c);
    if (value === undefined || value === '') {
      return '';
    }

    let numberScale: undefined | { plural: string };
    let unit = getDisplayUnit(c);

    if (Array.isArray(d)) {
      const column = d.find(col => col.code === c.code);
      numberScale = column?.numberScale ? getUnitDescription(column.numberScale) : undefined;
      unit = column?.unit ?? unit;
    }
    const decimal = c.validation?.decimal;

    if (c?.calculation?.formula) {
      return formatCalculationNumber(value, decimal);
    }

    if (Array.isArray(value)) {
      return value.map(v => getValueFromOptions(v, c.code, '')).join(', ');
    }

    return `${getValueFromOptions(value, c.code, unit, decimal) ?? ''}${numberScale ? ` ${numberScale.plural}` : ''}`;
  }

  if (extractColumnCode) {
    const col = columns.find(c => c.code === extractColumnCode);
    if (col) {
      if (rowData[0]) {
        return <>
          {getFormattedValueByColumn(rowData[0].data, col)}
        </>;
      }
      return null;
    }
  }

  const tableColumns: ColumnDef<RowDataInfo>[] = [];

  if (beforeAddons && beforeAddons.length) {
    tableColumns.push({
      id: 'beforeAddon',
      header: '',
      cell: ({ row }) => {
        // rowId means row index
        const rowIndex = row.original.id;
        const addon = beforeAddons.find((item) => item.rowIndex === rowIndex);
        return addon ? addon.element : null;
      },
    });
  }

  if (!disabled) {
    tableColumns.push({
      id: 'actions',
      header: '',
      meta: {
        cellProps: {
          className: 'table-input-view__actions',
        },
      },
      cell: ({ row }) => {
        const data = row.original.data;
        return data.length ? (
          <UncontrolledDropdown direction='down' data-testid={`table-input-view-actions-${row.id}`}>
            <DropdownToggle tag='div'>
              <i className='fas fa-ellipsis' />
            </DropdownToggle>
            <DropdownMenu className='table-input-view__actions' container='body'>
              {actions(row.original)}
            </DropdownMenu>
          </UncontrolledDropdown>
        ) : (
          <div style={{ height: '20px' }} />
        );
      },
    });

    tableColumns.push({
      id: 'status',
      header: '',
      meta: {
        cellProps: {
          className: 'table-input-view__status',
        },
      },
      cell: ({ row }) => {
        const data = row.original.data;
        return data.length ? <div>{status(row.original)}</div> : <div style={{ height: '20px' }} />;
      },
    });
  }

  columns.forEach((c, i) => {
    tableColumns.push({
      id: `${c.code}-${i}`,
      header: c.shortName ?? c.name,
      meta: {
        cellProps: {
          className: 'text-truncate dont_translate',
        },
      },
      cell: ({ row }) => {
        const formattedValueByColumn = getFormattedValueByColumn(row.original.data, c);
        if ('id' in row.original && validations) {
          const validationRow = validations.find(v => v.id === row.original.id);
          const validation = validationRow?.items.filter(v => v.columnCode === c.code) ?? [];
          return (
            <ValueWithValidation
              rowId={row.original.id}
              columnCode={c.code}
              value={formattedValueByColumn}
              validation={validation} />
          );
        }
        return formattedValueByColumn;
      }
    })
  });

  if (afterAddons && afterAddons.length) {
    tableColumns.push({
      id: 'afterAddon',
      header: '',
      cell: ({ row }) => {
        // rowId means row index
        const rowIndex = Number(row.original.id);
        const afterAddon = afterAddons.find((item) => item.rowIndex === rowIndex);
        return afterAddon ? afterAddon.element : null;
      },
    });
  }

  const getRowsData = () => {
    const rowHeaders = columns.map(col => col.shortName ?? col.name);
    const rowValues = rowData.map(row => {

      const lookupMap = new Map(row.data.map((valueColumn) => [valueColumn.code, valueColumn]))
      return columns.map(col => {
        const inputColumn = lookupMap.get(col.code);
        if (!inputColumn) {
          return undefined;
        }
        return Array.isArray(inputColumn.value) ?
          inputColumn.value.map(v => getValueFromOptions(v, col.code, '')).join(', ') :
          getValueFromOptions(inputColumn.value, col.code, '')
      })
    })
    return { rowHeaders, rowValues };
  }

  const downloadCSV = () => {
    const { rowHeaders, rowValues } = getRowsData();

    const data = {
      'fields': rowHeaders,
      'data': rowValues
    }

    const csvData = Papa.unparse(data);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    FileSaver.saveAs(blob, 'table.csv');
  }
  const editClass = disabled ? 'view-mode' : 'edit-mode';

  const tableData = (rowData.length ? rowData : [emptyData]);

  return (
    <div className={classNames(props.className, 'table-input-view-container', editClass)}>
      <div className={classNames({ 'float-right': !isDownloadBtnBottom, 'download-btn--bottom': isDownloadBtnBottom })}>
        <SimpleTooltip text={`${rowData.length === 0 ? 'Add at least one row of data.' : ''}`}>
          {showTableDownload ? (
            <Button color='link-secondary' className='p-0' onClick={downloadCSV} disabled={rowData.length === 0}>
              <i className='fal fa-file-csv' />
            </Button>
          ) : (
            <></>
          )}
        </SimpleTooltip>
      </div>
      <Table
        columns={tableColumns}
        data={tableData}
        editRowId={editRowId}
        hasBeforeAddon={!!beforeAddons?.length}
        hasAfterAddon={!!afterAddons?.length}
      />
    </div>
  );
};
export default TableInputView;

import { useLazyGetBackgroundReportUrlQuery } from '@api/survey-background-report';
import Dashboard from '@components/dashboard';
import { exportToExcel } from '@components/downloads/util/exportToExcel';
import { QueryWrapper } from '@components/query/QueryWrapper';
import { ROUTES } from '@constants/routes';
import { NoData, Option } from '@g17eco/molecules';
import { useSiteAlert } from '@hooks/useSiteAlert';
import { generateUrl } from '@routes/util';
import { DATE, formatDateUTC } from '@utils/date';
import { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { OnChangeValue } from 'react-select';
import { IN_PROGRESS_JOB_STATUSES, JobStatus } from '@g17eco/types/background-jobs';
import {
  AssessmentData,
  MaterialitySurveyModelMinData,
  useGenerateFinancialPPTXReportQuery,
  useGenerateScoresQuery,
  useGetMappedUniversalTrackersQuery,
  useRegenerateScoresMutation,
} from '../../api/materiality-assessment';
import { assessmentDataColumnMap, AssessmentDataRow, getResultByScoreDesc } from '../../utils';
import { AssessmentDropdown } from '../assessment/AssessmentDropdown';
import { AssessmentInsightsOverview } from './AssessmentInsightsOverview';
import { AssessmentInsightsResult } from './AssessmentInsightsResult';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { DetailedReportGenerator } from './assessment-reports/detailed-report-generator';
import { Packer } from 'docx';
import { saveAs } from 'file-saver';
import { InitiativeData } from '@g17eco/types/initiative';
import { BlockingLoader } from '@components/loader/BlockingLoader';

interface Props {
  initiative: InitiativeData;
  selectedSurvey: MaterialitySurveyModelMinData;
  surveys: MaterialitySurveyModelMinData[];
}

const shouldContinuePolling = (status: JobStatus) => IN_PROGRESS_JOB_STATUSES.includes(status);

const POLLING_INTERVAL = 10000;

export const AssessmentInsights = (props: Props) => {
  const { initiative, selectedSurvey, surveys } = props;
  const generateScoreQuery = useGenerateScoresQuery({ initiativeId: initiative._id, surveyId: selectedSurvey._id });
  const { result, jobId: scoreJobId, status: scoreJobStatus } = generateScoreQuery.data ?? {};
  const { data: mappedUtrs = [], isLoading } = useGetMappedUniversalTrackersQuery(
    scoreJobId
      ? {
          initiativeId: initiative._id,
          surveyId: selectedSurvey._id,
          jobId: scoreJobId,
        }
      : skipToken
  );

  const hasFinishedScores = scoreJobStatus && !shouldContinuePolling(scoreJobStatus);

  const [regenerateScoreQuery] = useRegenerateScoresMutation();
  const generateFinancialPPTXReportQuery = useGenerateFinancialPPTXReportQuery(
    // Only fetch the report if the scores have finished generating
    scoreJobId && hasFinishedScores ? { jobId: scoreJobId, initiativeId: initiative._id, surveyId: selectedSurvey._id } : skipToken
  );
  const [getBackgroundReportUrl] = useLazyGetBackgroundReportUrlQuery();

  const history = useHistory();
  const { addSiteError } = useSiteAlert();

  const {
    status: pptxReportJobStatus,
    jobId: pptxReportJobId,
    taskId: pptxReportTaskId,
  } = generateFinancialPPTXReportQuery.data ?? {};


  const hasFinishedPPTXReport = pptxReportJobStatus && !shouldContinuePolling(pptxReportJobStatus);

  useEffect(() => {
    // Define a function to determine if polling should continue
    if (!hasFinishedScores) {
      const intervalId = setInterval(() => {
        generateScoreQuery.refetch();
      }, POLLING_INTERVAL);

      return () => clearInterval(intervalId);
    }
  }, [generateScoreQuery, hasFinishedScores]);

  useEffect(() => {
    // Define a function to determine if polling should continue
    if (!hasFinishedPPTXReport && !generateFinancialPPTXReportQuery.isUninitialized) {
      const intervalId = setInterval(() => {
        generateFinancialPPTXReportQuery.refetch();
      }, POLLING_INTERVAL);
      return () => clearInterval(intervalId);
    }
  }, [hasFinishedPPTXReport, generateFinancialPPTXReportQuery]);

  const handlePPTXDownload = useCallback(
    ({ jobId, taskId }: { jobId?: string | null; taskId?: string | null }) => {
      if (!jobId || !taskId) {
        return;
      }

      getBackgroundReportUrl({ surveyId: selectedSurvey._id, jobId, taskId })
        .then((response) => {
          const { downloadUrl } = response.data || {};
          if (downloadUrl) {
            window.open(downloadUrl, '_blank', '');
          } else {
            addSiteError('Unable to download report. If this occurs again, please generate a new report.');
          }
        })
        .finally(() => history.replace(location.pathname));
    },
    [addSiteError, getBackgroundReportUrl, history, selectedSurvey._id]
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const jobId = searchParams.get('jobId');
    const taskId = searchParams.get('taskId');
    handlePPTXDownload({ jobId, taskId });
  }, [handlePPTXDownload, history, selectedSurvey._id]);

  const sortedDataByScoreDesc = useMemo(() => {
    if (!result) {
      return [] as AssessmentData[];
    }
    return result.financial.slice().sort((a, b) => b.score - a.score);
  }, [result]);

  const regenerateScores = () => regenerateScoreQuery({ initiativeId: initiative._id, surveyId: selectedSurvey._id });

  const handleExcelDownload = () => {
    const rowHeaders = Object.values(assessmentDataColumnMap);
    const convertedResult = result ? getResultByScoreDesc(result.financial) : [];
    const rowValues = convertedResult.map((row: AssessmentDataRow) => {
      return Object.keys(assessmentDataColumnMap).map((key) => row[key as keyof AssessmentDataRow] ?? '');
    });

    exportToExcel({
      headers: rowHeaders,
      values: rowValues,
      fileName: `${
        selectedSurvey.name ?? formatDateUTC(selectedSurvey.effectiveDate, DATE.YEAR_ONLY)
      } Assessment Results`,
      sheetName: 'assessment-results',
    });
  };

  const handleDocxDownload = async () => {
    if (!result) {
      return;
    }

    const doc = await DetailedReportGenerator({
      survey: selectedSurvey,
      financialResult: result.financial,
      initiative,
      mappedUtrs,
    });

    return Packer.toBlob(doc).then((blob) => {
      saveAs(
        blob,
        `${selectedSurvey.name ?? formatDateUTC(selectedSurvey.effectiveDate, DATE.YEAR_ONLY)} Assessment Results.docx`
      );
    });
  };

  const onClickItem = (option: OnChangeValue<Option<string>, false>) => {
    if (option?.value === selectedSurvey._id) {
      return;
    }
    history.push(generateUrl(ROUTES.MATERIALITY_TRACKER_INSIGHTS, { initiativeId: initiative._id, surveyId: option?.value }));
  };

  return (
    <Dashboard className='assessmentContainer'>
      <QueryWrapper
        query={generateScoreQuery}
        onError={() => <NoData text='There was an error fetching your results. Please try again.' />}
        onNoData={() => <NoData text='No assessment results currently available.' />}
        onSuccess={() => {
          return (
            <>
              {isLoading ? <BlockingLoader /> : null}
              <div className='d-flex pl-3 mb-3'>
                <AssessmentDropdown selectedSurvey={selectedSurvey} surveys={surveys} onClickItem={onClickItem} />
              </div>
              <AssessmentInsightsOverview
                initiative={initiative}
                pptxReportJobStatus={pptxReportJobStatus}
                scoreJobStatus={scoreJobStatus}
                onExcelDownload={handleExcelDownload}
                onPPTXDownload={() => handlePPTXDownload({ jobId: pptxReportJobId, taskId: pptxReportTaskId })}
                onDocxDownload={handleDocxDownload}
                regenerateScores={regenerateScores}
              />
              {scoreJobStatus === JobStatus.Completed ? (
                <AssessmentInsightsResult key={sortedDataByScoreDesc.length} data={sortedDataByScoreDesc} />
              ) : null}
            </>
          );
        }}
      />
    </Dashboard>
  );
};

import { useDeleteWorkgroupMutation, useDuplicateWorkgroupMutation, useGetWorkgroupsQuery } from '@api/workgroups';
import { QueryWrapper } from '@components/query/QueryWrapper';
import { ColumnDef, SimpleTooltip, Table } from '@g17eco/molecules';
import { Workgroup } from '@g17eco/types/workgroup';
import { formatHumaniseDate } from '@utils/date';
import { naturalSort } from '@utils/index';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Input, UncontrolledDropdown } from 'reactstrap';
import { getCreatingUrl, getEditingUrl } from './utils';
import { generateErrorToast, generateToast } from '@components/toasts';
import Loader from '@components/loader';

const READ_ONLY_TOOLTIP = 'Workgroups can only be manage on the root subsidiary';

type Props = {
  initiativeId: string;
  readOnly: boolean;
};

export const WorkgroupsTable = ({ initiativeId, readOnly }: Props) => {
  const history = useHistory();
  const getWorkgroupsQuery = useGetWorkgroupsQuery(initiativeId);
  const [deleteWorkgroup, { isLoading: isDeleting }] = useDeleteWorkgroupMutation();
  const [duplicateWorkgroup, { isLoading: isDuplicating }] = useDuplicateWorkgroupMutation();
  const [search, setSearch] = useState('');

  const handleEdit = (workgroupId: string) => {
    history.push(getEditingUrl(initiativeId, workgroupId));
  };
  const handleDuplicate = (workgroupId: string) => {
    duplicateWorkgroup({ initiativeId, workgroupId })
      .unwrap()
      .then(() => {
        generateToast({
          title: 'Workgroup duplicated',
          color: 'success',
          message: 'Workgroup has been duplicated',
        });
      })
      .catch((error) => {
        generateErrorToast(error);
      });
  };
  const handleDelete = (workgroupId: string) => {
    deleteWorkgroup({ initiativeId, workgroupId })
      .unwrap()
      .then(() => {
        generateToast({
          title: 'Workgroup deleted',
          color: 'success',
          message: 'Workgroup has been deleted',
        });
      })
      .catch((error) => {
        generateErrorToast(error);
      });
  };
  const columns: ColumnDef<Workgroup>[] = [
    {
      accessorKey: 'icon',
      header: '',
      cell: ({ row }) => {
        return <i className={`fal fa-${row.original.icon}`} style={{ color: row.original.color }} />;
      },
    },
    {
      accessorKey: 'name',
      header: 'Name',
    },
    {
      accessorKey: 'users.length',
      header: 'Workgroup size',
      accessorFn: (row) => `${row.users.length} users`,
    },
    {
      accessorKey: 'created',
      header: 'Created',
      accessorFn: (row) => formatHumaniseDate(row.created),
      sortingFn: (a, b) => naturalSort(a.original.created, b.original.created),
    },
    {
      id: 'buttons',
      header: '',
      cell: (c) =>
        readOnly ? (
          <SimpleTooltip text={READ_ONLY_TOOLTIP}>
            <i className='fa-light fa-circle-info ml-1' />
          </SimpleTooltip>
        ) : (
          <UncontrolledDropdown>
            <DropdownToggle outline color='transparent' className='border-ThemeNeutralsLight px-2'>
              <i className={'fal fa-bars'}></i>
            </DropdownToggle>
            <DropdownMenu className='border-ThemeBorderDefault'>
              <DropdownItem onClick={() => handleEdit(c.row.original._id)}>
                <i className='fal fa-pencil mr-2' />
                Edit
              </DropdownItem>
              <DropdownItem onClick={() => handleDuplicate(c.row.original._id)}>
                <i className='fal fa-copy mr-2' />
                Duplicate
              </DropdownItem>
              <DropdownItem onClick={() => handleDelete(c.row.original._id)}>
                <i className='fal fa-trash mr-2' />
                Delete
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        ),
    },
  ];
  return (
    <>
      {isDeleting || isDuplicating ? <Loader /> : null}
      <div className='d-flex gap-3 justify-content-between mb-3'>
        <Input placeholder='Search for workgroup' value={search} onChange={(e) => setSearch(e.target.value)} />
        <SimpleTooltip text={readOnly ? READ_ONLY_TOOLTIP : ''}>
          <Button
            color='primary'
            onClick={() => history.push(getCreatingUrl(initiativeId))}
            className='text-nowrap'
            disabled={readOnly}
          >
            <i className='fal fa-users-medical me-2' />
            Create workgroup
          </Button>
        </SimpleTooltip>
      </div>
      <QueryWrapper
        query={getWorkgroupsQuery}
        onSuccess={(data) => {
          const filteredData = data.filter((d) => d.name.toLowerCase().includes(search.toLowerCase()));
          return <Table data={filteredData} columns={columns} pageSize={10} responsive />;
        }}
        onNoData={() => <p>No available workgroups</p>}
      />
    </>
  );
};

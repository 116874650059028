import { useGetOrganizationMetricGroupsQuery } from '@api/organization';
import { getFrameworksOptions, getStandardsOptions } from '@constants/standards-frameworks';
import { PACK, SURVEY } from '@constants/terminology';
import { SelectFactory, SelectTypes } from '@g17eco/molecules';
import { Scope, ScopeGroups } from '@models/surveyData';
import { getCustomGroupOptions } from '@utils/metricGroup';
import { useMemo } from 'react';
import { getGroup, getGroupChildrenTags } from '@g17eco/core';

interface Props {
  initiativeId: string;
  modules: string[];
  onChange: (value: string[]) => void;
  scope?: Scope
}

const standardsOptions = getStandardsOptions();
const frameworksOptions = getFrameworksOptions();

// TODO: Move this component to @app/common when all related components are moved onto @app
export const ModuleDropdown = ({ initiativeId, onChange, modules, scope }: Props) => {
  const { data: metricGroups } = useGetOrganizationMetricGroupsQuery(initiativeId);

  const options = useMemo(() => {
    const customGroupOptions = getCustomGroupOptions(metricGroups ?? []);
    if (!scope) {
      return [...customGroupOptions, ...standardsOptions, ...frameworksOptions];
    }

    const standardCodes = scope[ScopeGroups.Standards];
    const frameworkCodes = scope[ScopeGroups.Frameworks];

    return [
      ...customGroupOptions.filter(op => scope[ScopeGroups.Custom].includes(op.value)),
      ...standardsOptions.filter(op => {
        if (standardCodes.includes(op.value)) {
          return true;
        }
        const group = getGroup('standards', op.value);
        if (!group) {
          return false;
        }
        const groupChildrenTags = new Set(getGroupChildrenTags(group));
        // partial scope
        return standardCodes.some((tag) => groupChildrenTags.has(tag));
      }),
      ...frameworksOptions.filter((op) => {
        // We are dealing options of subgroups here, so those must be explicitly added
        if (frameworkCodes.includes(op.value)) {
          return true;
        }
        const group = getGroup(op.scopeType, op.frameworkCode);
        // Root framework is added, so all subgroups are included
        return group ? frameworkCodes.includes(group.code) : false ;
      })
    ]
  }, [metricGroups, scope])

  return (
    <SelectFactory
      selectType={SelectTypes.MultipleSelect}
      placeholder={`Select ${SURVEY.ADJECTIVE} ${PACK.SINGULAR}`}
      options={options}
      onChange={onChange}
      values={modules}
      className='w-100'
    />
  );
};

import { useCallback, useState } from 'react';
import UniversalTracker, { UniversalTrackerBase } from '../../../../model/UniversalTracker';
import ValueList, { ValueListProps } from '../../../../components/survey/form/input/ValueList';
import { FormGroup, Label } from 'reactstrap';
import { SimpleTooltip } from '../../../../molecules';

interface AssessmentFormGroupProps {
  utr?: UniversalTrackerBase;
  tooltip?: string;
  onChange: (key: string, value?: string) => void;
}

export const AssessmentFormGroup = ({ utr, tooltip, onChange }: AssessmentFormGroupProps) => {
  const [valueDataData, setValue] = useState<string | undefined>(undefined);

  const handleValueDataChange = useCallback(
    (v?: string) => {
      if (!utr) {
        return;
      }
      setValue(v);
      onChange(utr.code, v);
    },
    [onChange, utr]
  );

  if (!utr) {
    return <>Missing UTR</>;
  }

  const valueListProps: ValueListProps = {
    isDisabled: () => false,
    saving: false,
    universalTracker: new UniversalTracker(utr),
    handleValueDataChange,
    valueDataData,
  };

  return (
    <FormGroup>
      <Label htmlFor='direct-employed-staff' className='w-100 strong'>
        {utr.name}

        {tooltip ? (
          <SimpleTooltip className='float-right' text={tooltip}>
            <i className='fal text-xl fa-info-circle' />
          </SimpleTooltip>
        ) : null}
      </Label>

      <ValueList {...valueListProps} />
    </FormGroup>
  );
};

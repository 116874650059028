import { CalculationType } from '@g17eco/types/utrv-connections';
import { ConnectionProps } from './types';
import { SimpleTooltip } from '@g17eco/molecules';

export const DetailsTooltip = ({
  connection,
  displayDetails,
  utrs,
}: Pick<ConnectionProps, 'connection' | 'displayDetails' | 'utrs'>) => {
  if (!displayDetails || connection.type !== CalculationType.Direct) {
    return null;
  }

  const variable = connection.variables[connection.direct];
  if (!variable) {
    return null;
  }

  const utr = utrs.find((utr) => utr.code === variable.code);
  if (!utr) {
    return null;
  }

  const tooltip = (
    <div className={'text-left'}>
      <span>Direct Calculation: {connection.name}</span>
      <br />
      {connection.description ? <div>{connection.description}</div> : null}
      <span key={variable.code}>
        <h6 className={'text-ThemeTextMedium mb-0'}>Direct Universal Tracker</h6>
        <span>code: {variable.code}</span>
        <br />
        <span>_id: {utr._id}</span>
        <br />
        {variable.valueListCode ? `#${variable.valueListCode} ` : ' '}
        {variable.integrationCode ? `(${variable.integrationCode})` : ''}
      </span>
    </div>
  );

  return (
    <SimpleTooltip text={tooltip}>
      <i className={'fa-light fa-info-circle text-ThemeIconSecondary cursor-pointer'} />
    </SimpleTooltip>
  );
};
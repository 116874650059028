import { ViewDropdown } from './ViewDropdown';
import { Toggle } from '@g17eco/molecules';
import { SurveyDelegationProps } from './types';
import { ManageDelegatesBtn } from './ManageDelegatesBtn';
import { DelegatedUsers } from '@api/survey-users';

type Props = Pick<SurveyDelegationProps, 'view' | 'onChangeView' | 'surveyId' | 'isReadOnly'> & {
  platformDelegatesIncluded: boolean;
  onTogglePlatformDelegatesIncluded: () => void;
  users?: DelegatedUsers;
};

export const Toolbar = ({
  view,
  onChangeView,
  platformDelegatesIncluded,
  onTogglePlatformDelegatesIncluded,
  users,
  surveyId,
  isReadOnly,
}: Props) => {
  return (
    <div className='d-flex align-items-center mt-3'>
      <span className='me-2'>View:</span>
      <ViewDropdown view={view} onChangeView={onChangeView} />
      <Toggle
        className={{ form: 'd-flex align-items-center pl-0 mb-0 ms-4 justify-content-end', label: 'text-md ms-2' }}
        checked={platformDelegatesIncluded}
        onChange={onTogglePlatformDelegatesIncluded}
        label='Platform delegated users'
      />
      <ManageDelegatesBtn users={users} surveyId={surveyId} isReadOnly={isReadOnly} />
    </div>
  );
};
